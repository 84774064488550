import React , {useState}from 'react'
import './Testomonial.css'
import {testimonialsData} from "../../data/testimonialsData";
import rightArrow from '../images/rightArrow.png';
import leftArrow from '../images/leftArrow.png'
import {motion} from 'framer-motion'

function Testomonial() {
  const [selected,setSelected]= useState(0);
  const tlength= testimonialsData.length;
  const transition={  type:"spring",duration:3};
  return (
    <div className="Testomonials" id='test'>
        <div className="left-t">
<span > 
    Testimonials
    </span>
    <span className='stroke-text'>
What They
        </span>

        <span>
        Say about us
        </span>
        <span>{testimonialsData[selected].review}
        </span>
        <span>
        <span style={{color: 'var(--orange)'}}>
          {testimonialsData[selected].name}
         </span>{""}
         
        - {testimonialsData[selected].status}
        </span>
        </div>
        <div className="right-t">
          <motion.div transition={{...transition,duration:2}} whileInView={{opacity:1, x:0} } initial={{opacity:0, x:-100} }></motion.div>
          <motion.div transition={{...transition,duration:2}} whileInView={{opacity:1, x:0} } initial={{opacity:0, x:100} }></motion.div>
          <motion.img src={testimonialsData[selected].image}
          key={selected} initial={{opacity:0, x:100}} animate={{opacity:1, x:0}} exit={{opacity:0,x:-100}}
          ></motion.img>
        <div className="arrows">
          <img src={leftArrow } alt="" onClick={()=>{
            selected===0?setSelected(tlength-1):setSelected((prev)=>prev-1)

          }}/>
          <img src={rightArrow} alt="" onClick={()=>{
            selected===tlength-1?setSelected(0):setSelected((prev)=>prev+1)

          }}/>
        </div>

        </div>
    </div>
  )
}

export default Testomonial