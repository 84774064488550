import React from 'react'
import Header from './Header/Header'
import './Hero.css'
import hero_image from './images/hero_image.png';
import hero_image_back from './images/hero_image_back.png';
import Heart from './images/heart.png';
import Calories from './images/calories.png';
import {motion} from 'framer-motion';
import NumberCounter from 'number-counter';
export const Hero = () => {
    const transition= {type:'spring',duration:3};
  return (
    <div className='hero'>
         <div className="blur hero-blur"></div>
    <div className='left-h'>
<Header/>
<div className='the-best-ad'>
    <motion.div initial={{left:'238px'}}whileInView={{left:'8px'}} transition={{...transition,type:'tween'}}></motion.div>
    
    <span> The 1# Fitness Club In Town</span>
</div>
<div className='hero-text'>
     <div><  span className='stroke-text'>
         Shape
         </span>
         <span>
              Your
             </span>
             </div>
             <div><span>
         Ideal Body
         </span>
         </div>
        <div>
            <span className='generic-text'> 
            In here we will help you to shape and build your ideal body and live up your life to fullest
            </span>
        
             </div>
</div>
<div className="figures">
    <div><span>
       140+
        </span>
        <span>
            Expret Coaches
            </span>
            </div>
    <div><span>987+</span><span>Members Joined</span></div>
    <div><span>50+</span><span>Fitness Programs</span></div>
</div>
<div className="hero-buttons">
    <button className="btn">
        Get Started
        </button>
        <button className="btn">
            Learn More

        </button>
</div>
    </div>
    <div className='right-h'>
<button className='btn'>Join Now</button>
<motion.div className="heart-rate" transition={transition} whileInView={{right:'4rem'} } initial={{right:'-1rem'} }>
    <img src={Heart}></img>
    <span>
        Heart Rate</span><span>
            116 bpm
        </span>
</motion.div>
<img src={hero_image} className="hero-image"></img>
<motion.img src={hero_image_back} className="hero-image-back" transition={transition} whileInView={{right:'20rem'} } initial={{right:'11rem'} }></motion.img>
<motion.div className="calories" transition={transition} whileInView={{right:'37rem'} } initial={{right:'28rem'} }>
    
    <img src={Calories}></img>
    
    <div className='spanio'> 
    <span>Calories Burnt</span>
    <span>
        220 kcal
    </span>
    </div>
</motion.div>
    </div>
    </div>
  )
}
