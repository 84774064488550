import React from 'react'
import './Plans.css';
import {plansData} from '../../data/plansData'
import RightArrow from '../images/rightArrow.png';

import whitetick from '../images/whiteTick.png';

function Plans() {
  return (
      <div className="plans-container">
<div className="blur plans-blur-1"></div>
<div className="blur plans-blur-2"></div>
<div className="programs-header"style={{gap:'2rem'}}>
<spans className='stroke-text'>Ready TO start</spans>
<spans style={{color:'white'}}>Your Journey </spans>
<spans className='stroke-text'>Now with us</spans>
    </div>
    <div className="plans-card">
{plansData.map((plans,i)=>{
    return(
        <div className='plan'>
      {plans.icon}
      <span>{plans.name}</span>
      <span>{plans.price}$</span>
      <div className="feats">
          {plans.features.map((feat,i)=>{
              return(
                  <div className="feat"> 
                  <img src={whitetick}></img>
                  <span key={i}> {feat}</span>
                  </div>
              )
          })}
          
      </div>
      <div> <span> See more benefits -></span> 
      
      </div>
      <button className='btn'>Join Now</button>
        </div>

    )
})}
    </div>
    </div>
  )
}

export default Plans