import React, { useState } from 'react'
import './Header.css'
import Logo from '../../assets/logo.png';
import Bars from '../../assets/bars.png';

import {Link} from'react-scroll';
function Header() {
  const mobile=window.innerWidth<=768?true:false;
  const[menuOpened,setmenuOpened]= useState(false);
  
  return (
    <div className='header'>
        <img className='logo' src={Logo}/>
        {(menuOpened===false&&mobile===true)?(
          <div style={{backgroundColor:'var(--appColor)', padding:"0.5rem" , borderRadius:"5px"}} onClick={
            ()=>setmenuOpened(true)
          }><img src={Bars} style={{width:'1.5rem', height:"1.5rem"}}></img></div>
        ):  <ul className='header-menu'>
        <li onClick={()=>{
          setmenuOpened(false)
        }}>
          <Link onClick={()=>{
            setmenuOpened(false)
          }} to='hero' span={true} smooth={true}> 
          
          
          Homes</Link></li>
          
        <li onClick={()=>{
          setmenuOpened(false)
        }}>
          <Link onClick={()=>{
            setmenuOpened(false)
          }} to='programs' span={true} smooth={true}> 
          Program
         </Link> </li>
        <li onClick={()=>{
          setmenuOpened(false)
        }}>
          <Link onClick={()=>{
            setmenuOpened(false)
          }} to='reasons' span={true} smooth={true}> Why Us</Link></li>
        <li onClick={()=>{
          setmenuOpened(false)
        }}> <Link onClick={()=>{
          setmenuOpened(false)
        }} to='programs' span={true} smooth={true}> Plans
        </Link></li>
        <li onClick={()=>{
          setmenuOpened(false)
        }}> <Link onClick={()=>{
          setmenuOpened(false)
        }} to='test' span={true} smooth={true}> Testimonails</Link></li>
    </ul> }
       
    </div>
  )
}

export default Header;