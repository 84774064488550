import React from 'react'
import './Footer.css'
import Github from '../images/github.png';
import Instagram from '../images/instagram.png';
import LinkedIn from '../images/linkedin.png';
import Logo from '../images/logo.png';
function Footer() {
  return (
    <div className="Footer-container">
        <hr/>
        <div className="footer">
           <a href='github.com'> <img src={Github} alt="" /></a>
           <a href='Instagram.com'><img src={Instagram} alt="" /></a>
           <a href='LinkedIn.com'><img src={LinkedIn} alt="" /></a>
        </div>
        <div className="logo-f">
            <img src={Logo} alt="" />
        </div>
    </div>
  )
}

export default Footer