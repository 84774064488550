import React from 'react'
import './Join.css'

function Join() {
  return (
      <div className='Join' id='join-us'>
          <div className="left-j"> 
          <hr/>
    <div>
        <span className='stroke-text'>
            Ready to
        </span>
        <span> Level up
        </span>
        
    </div>
    <div>
        <span className='stroke-text'>
            Your body
            </span>
            <span> with us
            </span>
    </div>
    </div>
    <div className="right-j">
        <form action="sumbit" className='email'> 
        <button class="button-42" role="button">Register Now!</button>
        </form>
    </div>
    </div>
  )
}

export default Join