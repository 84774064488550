import React from 'react'
import './Reasons.css';
import image1 from '../images/image1.png'
import image2 from '../images/image2.png'
import image3 from '../images/image3.png'
import image4 from '../images/image4.png'
import nb from '../images/nb.png'
import adidas from '../images/adidas.png'
import nike from '../images/nike.png'
import tick from '../images/tick.png'
function Reasons() {
  return (
    <div className="Reasons" id='reasons'>
        <div className="left-r">
            
<img src={image1} alt="" />


<img src={image2} alt="" />
<img src={image3}alt="" />
<img src={image4} alt="" />

        </div>
        <div className="right-r">
<span>Best in the World</span>
<div >
   <span className='stroke-text'>
     Why
  </span>
  <span> Chose Us?</span>
    
    </div>
<div className='details-r'>
<div>
  <img src={tick} alt="">
    </img>
    <span>
      FREE PROGRAM FOR NEW MEMBER
      </span>
      </div>
<div>
  <img src={tick} alt="" />
  <span>
    Over 140+ Coaches
    </span>
    </div>
<div>
  <img src={tick} alt="" />
  <span>
    Train harder and Faster than before
    </span>
    </div>
<div>
  <img src={tick} alt="" />
  <span>
REALIABLE PARTNERS
    </span>
    </div>
    </div>
    <span style={{color: 'var(--gray)', fontWeight:'normal'}}>Our Partners</span>
    <div className='images'> 
    <img src={nb} style={{width: '2.5rem'}} alt="" />
    <img src={adidas} alt="" />
    <img src={nike} alt="" />
    </div>
        </div>


    </div>
  )
}

export default Reasons